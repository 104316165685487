import { render, staticRenderFns } from "./ComoFunciona.vue?vue&type=template&id=08e29c75&scoped=true&lang=pug"
import script from "./ComoFunciona.vue?vue&type=script&lang=js"
export * from "./ComoFunciona.vue?vue&type=script&lang=js"
import style0 from "./ComoFunciona.styl?vue&type=style&index=0&id=08e29c75&prod&lang=stylus&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08e29c75",
  null
  
)

export default component.exports