<template lang="pug">
    section#investimento
        h3 Investimento
        ul.investments
            li.investment(v-for="investment in investments")
                .img
                    img(:src="investment.image")
                p {{investment.text}}
            li.button
                router-link(:to="{name: 'home'}").roundedButton Conheça as propriedades
</template>

<script>
export default {
    name: "section-investimento",
    data() {
        return {
            investments: [
                {
                    image: require('@images/icons/imoveis.png'),
                    text: "Imóveis de alto padrão"
                },
                {
                    image: require('@images/icons/fireplace.png'),
                    text: "Estrutura completa de lazer"
                },
                {
                    image: require('@images/icons/pin.png'),
                    text: "Localização privilegiada"
                },
                {
                    image: require('@images/icons/beach.png'),
                    text: "Baixíssima sazonalidade (atrativos especialmente trabalhados em cada empreendimento)."
                },
                {
                    image: require('@images/icons/options.png'),
                    text: "Sistema de gestão de ocupação com absoluta transparência e competência"
                },
                {
                    image: require('@images/icons/selo.png'),
                    text: "Qualidade de produto atestada pelos empreendimentos já entregues"
                },
                {
                    image: require('@images/icons/document.png'),
                    text: "Imóveis com escritura pública e matrícula: patrimônio familiar para as próximas gerações"
                },
            ]
        }
    }
}
</script>

<style lang="stylus" scoped src="./Investimento.styl"></style>
