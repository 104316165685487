<template lang="pug">
    #bannerImagem
        img(:src="$props.image")
</template>

<script>
export default {
    name: "component-banner-imagem",
    props: {
        image: {
            type: String,
            required: true,
        }
    }
}
</script>

<style lang="stylus" scoped src="./BannerImagem.styl"></style>