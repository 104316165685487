<template lang="pug">
    section#lei
        p Veja a lei que regulamenta a multipropriedade
            strong LEI Nº 13.777, DE 20 DE DEZEMBRO DE 2018        
        a(href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/L13777.htm", target="_blank").roundedButton Veja aqui a lei completa 
            FontAwesomeIcon(:icon="icons.faArrowUpRightFromSquare")
</template>

<script>
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

export default {
    name: "component-lei",
    data() {
		return {
			icons: {
				faArrowUpRightFromSquare,
			}
		}
	},
}
</script>

<style lang="stylus" scoped src="./Lei.styl"></style>