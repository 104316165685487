<template lang="pug">
	main#comoFunciona
		BannerImagem(:image="conteudo.banner")
		TituloPagina(:titulo="conteudo.titulo")
		Investimento
		Lei
</template>

<script>
import BannerImagem from '@components/BannerImagem/BannerImagem'
import TituloPagina from '@components/TituloPagina/TituloPagina'
import Investimento from '@sections/ComoFunciona/Investimento/Investimento'
import Lei from '@components/Lei/Lei'

export default {
	name: "view-como-funciona",
	components: {
		BannerImagem,
		TituloPagina,
		Investimento,
		Lei,
	},
	metaInfo() {
		return {
			title: 'CA2A | Como Funciona',
			meta: [
				{ name: 'description', content: '' },
			],
		}
	},
	data() {
		return {
			conteudo: {
				banner: require('@images/backgrounds/como-funciona.jpg'),
				titulo: {
					textoMenor: "um pouco mais sobre ",
					textoMaior: "Como funciona"
				}
			}
		}
	}
}
</script>

<style lang="stylus" scoped src="./ComoFunciona.styl"></style>