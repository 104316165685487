<template lang="pug">
    section#tituloPagina
        h2
            span(v-if="$props.titulo.textoMenor") {{$props.titulo.textoMenor}}
            | {{$props.titulo.textoMaior}}
</template>

<script>
export default {
    name: "component-titulo-pagina",
    props: {
        titulo: {
            type: Object,
            required: true,
        }
    }
}
</script>

<style lang="stylus" scoped src="./TituloPagina.styl"></style>